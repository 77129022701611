/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 26번째 로그에서는 루비 카이기 2019, 쿡패드, 일본 개발 커뮤니티 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019"
  }, "RubyKaigi 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/6/"
  }, "stdout_006.log: KT 서울 서북부 통신 장애, AWS re:Invent 2018 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/park/10851558"
  }, "대한민국 vs 일본홋카이도 면적 비교.jpg : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%ED%99%8B%EC%B9%B4%EC%9D%B4%EB%8F%84_%EC%9D%B4%EB%B6%80%EB%A6%AC_%EB%8F%99%EB%B6%80_%EC%A7%80%EC%A7%84"
  }, "홋카이도 이부리 동부 지진 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/2016%EB%85%84_%EA%B2%BD%EC%A3%BC_%EC%A7%80%EC%A7%84"
  }, "2016년 경주 지진 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8F%84%ED%98%B8%EC%BF%A0_%EC%A7%80%EB%B0%A9_%ED%83%9C%ED%8F%89%EC%96%91_%ED%95%B4%EC%97%AD_%EC%A7%80%EC%A7%84"
  }, "도호쿠 지방 태평양 해역 지진 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8F%84%EC%B9%B4%EC%9D%B4_%EC%A7%80%EC%A7%84"
  }, "도카이 지진 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=NSBjEvPH2j4"
  }, "Japan Earthquakes 2011 Visualization Map - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%ED%9B%84%EC%BF%A0%EC%98%A4%EC%B9%B4%ED%98%84"
  }, "후쿠오카현 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019/presentations/riseshia.html#apr20"
  }, "Cleaning up a huge ruby application - RubyKaigi 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cookpad.com/"
  }, "(일본어) 쿡패드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloudnativedays.jp/cndf2019/"
  }, "CloudNative Days Fukuoka 2019 - #CNDF2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yahoo.co.jp/"
  }, "Yahoo! JAPAN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/economy/view/2018/07/433696/"
  }, "소프트뱅크 그룹, 야후재팬 지분 48%로 확대 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloudnativedays.jp/cndf2019/"
  }, "CloudNative Days Fukuoka 2019 - #CNDF2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://linefukuoka.co.jp/ja/"
  }, "LINE Fukuoka Corporation | ホーム")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://goo.gl/maps/bDbELSw9QuUbZvMw7"
  }, "Google Map - 쿡패드 주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/ko-KR/Seoul-Ruby-Meetup/"
  }, "Seoul Ruby Meetup (서울, 한국(대한민국)) | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/rubyonrailslab/"
  }, "ROR Lab. - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pycon.kr/"
  }, "파이콘 한국 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://asakusarb.esa.io/"
  }, "asakusarb.esa.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/search?f=tweets&vertical=default&q=%23cndf2019&src=typd"
  }, "#cndf2019 - Twitter Search")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tweetdeck.twitter.com/"
  }, "TweetDeck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%96%B8%EC%BD%98%ED%8D%BC%EB%9F%B0%EC%8A%A4"
  }, "언콘퍼런스 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/49"
  }, "Seoul.js Lightning Talk | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sendagayarb.doorkeeper.jp/"
  }, "Sendagaya.rb | Doorkeeper")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/pyjog/"
  }, "PyJog - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/183"
  }, "Seocho.rb 첫 번째 모임: 서버리스 루비 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dockerjp.connpass.com/event/5640/"
  }, "Docker Meetup Tokyo #2 - connpass")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2016/"
  }, "RubyKaigi 2016")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/local/siteview.nhn?code=18772593"
  }, "대각빌딩: 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://awskrug.github.io/index.html"
  }, "AWSKRUG - AWS 한국사용자모임")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://classmethod.jp/"
  }, "(일본어) AWS 전업의 클래스메서드 ｜ 시스템 설계 운용 기술 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/jp/solutions/case-studies/cookpad/"
  }, "(일본어) AWS 도입 사례: 쿡패드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.duetdisplay.com/"
  }, "Duet Display - Ex-Apple Engineers Turn Your iPad Into An Extra Display")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wptavern.com/disqus-hits-sites-with-unwanted-advertising-plans-to-charge-large-publishers-a-monthly-fee-to-remove-ads"
  }, "Disqus Hits Sites with Unwanted Advertising, Plans to Charge Large Publishers a Monthly Fee to Remove Ads – WordPress Tavern")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/business/view/2014/08/1111627/"
  }, "세계최대의 레시피사이트, 쿡패드 이야기 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%A7%88%EC%B9%B4%EB%A1%B1"
  }, "마카롱 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://linq.career-tasu.jp/magazine/cookpad/"
  }, "(일본어) 쿡패드 주식회사: 주방을 갖춘 오피스, 그 진의를 찾아서! - Careertasu LINQ 매거진")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techlife.cookpad.com/"
  }, "(일본어) 쿡패드 개발자 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cookpad-mart.com/"
  }, "(일본어) 쿡패드 마트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Last_mile"
  }, "Last mile - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://b.hatena.ne.jp/"
  }, "(일본어) 하테나 북마크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko"
  }, "프로그래밍 클라우드 기술 블로그 — 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ziwon.dev/post/modern-network-load-balancing-and-proxying/"
  }, "번역 - 최신 네트워크 로드 밸런싱 및 프록시 소개 | 人生は儚く短い。")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/awskorea/aws-system-manager-parameter-store-aws-aws-summit-seoul-2019"
  }, "AWS System Manager: Parameter Store를 사용한 AWS 구성 데이터 관리 기법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.spacee.jp/listings/12854"
  }, "(일본어) 원코인 회의실 NEW OPEN!! 후쿠오카 - Spacee")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20190415153902596"
  }, "\"핸드폰 고장 난 줄\"..KT고객, LTE 속도저하 불만 폭주 | Daum 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/ruby-2-6-0-released-and-changes"
  }, "루비(Ruby) 2.6 릴리즈 및 주요 문법 변경 사항 소개 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mercan.mercari.com/entry/2018/03/09/113000"
  }, "Hackathon on the Other Side of the World? GOT’s Mikako Discusses the Indian Hiring Project That Won Her Mercari’s 2nd Quarter MVP Award - mercan（メルカン）")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://elixir-lang.org/"
  }, "Programming Language Elixir")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://daybit.com/"
  }, "DAYBIT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/byteconf/stripe-is-building-a-ruby-typechecker-d6cd7cee6abf"
  }, "Stripe is building a Ruby typechecker – Byteconf – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/direnv/direnv"
  }, "direnv/direnv: Unclutter your .profile")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/en/news/2019/03/31/support-of-ruby-2-3-has-ended/"
  }, "Support of Ruby 2.3 has ended")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sourcediving.com/three-stories-about-global-cooking-2da62d0c8f64"
  }, "Three stories about Global Cooking – Source Diving")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://corporate.m3.com/"
  }, "(일본어) M3 Inc")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kotra.or.kr/user/globalAllBbs/kotranews/album/2/globalBbsDataAllView.do?dataIdx=141696&column=&search=&searchAreaCd=&searchNationCd=101003&searchTradeCd=&searchStartDate=&searchEndDate=&searchCategoryIdxs=&searchIndustryCateIdx=&page=8&row=80"
  }, "日, 의사들이 매일 찾는 의료정보포털 m3.com - 뉴스 - KOTRA 해외시장뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://overseas.mofa.go.kr/jp-fukuoka-ko/brd/m_1631/view.do?seq=789022&srchFr=&srchTo=&srchWord=&srchTp=&multi_itm_seq=0&itm_seq_1=0&itm_seq_2=0&company_cd=&company_nm=&page=17"
  }, "후쿠오카시 인구 146만명으로, 6위 교토시를 맹추격 상세보기|자치교류정보주 후쿠오카 대한민국 총영사관")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/eks/?nc1=h_ls"
  }, "Amazon EKS – 관리형 Kubernetes 서비스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
